// Main imports
import Vue from 'vue'
import Vuetify from 'vuetify'

// // Vuetify locales
import es from 'vuetify/es5/locale/es'

const colors = {
  primary: '#004e87',
  secondary: '#004a83',
  accent: '#4b4846'
}

// Create component
Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es'
  },

  icons: {
    iconfont: 'mdi',
    values: {
      cancel: 'mdi-close',
      prev: 'mdi-chevron-left',
      next: 'mdi-chevron-right'
    }
  },

  theme: {
    dark: false,
    themes: {
      dark: colors,
      light: colors
    },

    options: {
      customProperties: true
    }
  }
})
