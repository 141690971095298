<template>
  <v-row align="center" justify="center" class="mx-0 webpage full-height">
    <img
      v-if="$vuetify.breakpoint.width >= 600"
      class="gm-logo--full-height"
      :src="$assets.logoNormalCut"
    >
    <v-col cols="12" sm="7" lg="8">
      <v-row>
        <v-col
          cols="12"
          :class="$vuetify.breakpoint.width < 600 ? '' : 'py-12 gm-information'"
        >
          <div class="d-flex align-center mb-3">
            <h3 class="mr-10 primary--text gm-headline-3">
              {{ $t('home.section8.contact.title') }}
            </h3>
            <v-img max-width="45px" :src="$assets.eight2" />
          </div>

          <div class="d-flex align-center">
            <v-icon class="mr-2" color="grey darken-4">
              mdi-email-outline
            </v-icon>
            <span
              style="font-size: 1.2rem"
              class="ml-5 grey--text text--darken-4"
            >
              {{ $t('home.section8.contact.email') }}
            </span>
          </div>

          <div class="d-flex align-center mb-5">
            <v-icon class="mr-2" color="green darken-1">mdi-whatsapp</v-icon>

            <v-btn
              href="http://wa.me/50769793073"
              rounded
              style="font-size: 1.2rem"
              color="grey darken-4"
              text
            >
              {{ $t('home.section8.contact.phone') }}
            </v-btn>
          </div>

          <div class="d-flex align-center">
            <h3 class="mr-10 primary--text gm-headline-3">
              {{ $t('home.section8.address.title') }}
            </h3>
            <v-img max-width="45px" :src="$assets.eight2" />
          </div>

          <p class="grey--text text--darken-4 gm-headline-4">
            {{ $t('home.section8.address.principal.title') }}:
          </p>
          <p class="grey--text text--darken-4">
            {{ $t('home.section8.address.principal.cities')[0] }}<br>
            {{ $t('home.section8.address.principal.cities')[1] }}<br>
            {{ $t('home.section8.address.principal.cities')[2] }}<br>
            {{ $t('home.section8.address.principal.cities')[3] }}<br>
            {{ $t('home.section8.address.principal.cities')[4] }}
          </p>

          <p class="grey--text text--darken-4 gm-headline-4">
            {{ $t('home.section8.address.others.title') }}:
          </p>

          <p class="grey--text text--darken-4">
            {{ $t('home.section8.address.others.cities') }}
          </p>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      sm="5"
      lg="4"
      class="mx-auto pa-0 d-flex align-center justify-center"
    >
      <v-img
        class="gm-floating-img"
        :max-width="$vuetify.breakpoint.width < 600 ? '' : '40vw'"
        :src="$assets.eight1"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.webpage {
  position: relative;
  overflow-y: hidden;
}

.gm-logo {
  &--full-height {
    position: absolute;
    height: 80vh;
    bottom: -2%;
    left: -2%;
  }
}

.gm-information {
  z-index: 2;
  padding-left: 40vh;
}
</style>
