<template>
  <div>
    <v-navigation-drawer
      v-if="isMobile"
      v-model="drawer"
      color="grey darken-4"
      fixed
      app
    >
      <v-list>
        <template v-for="(item, i) in links">
          <v-list-item
            v-if="'scroll' in item"
            :key="i"
            href="#"
            router
            exact
            @click.prevent="goToSection(item.scroll)"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else :key="i" :to="item.to" router exact>
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      id="appBar"
      :color="appBarColor"
      fixed
      app
      :elevation="appBarElevation"
    >
      <v-app-bar-nav-icon v-if="isMobile" @click.stop="drawer = !drawer" />

      <img v-if="showLogo" style="height: 100%" :src="$assets.logoWhite">

      <v-spacer />
      <template v-if="!isMobile">
        <template v-for="(item, i) in links">
          <v-btn
            v-if="'scroll' in item"
            :key="i"
            rounded
            class="mx-1"
            text
            href="#"
            color="white"
            @click.prevent="goToSection(item.scroll)"
          >
            {{ item.title }}
          </v-btn>
          <v-btn
            v-else
            :key="i"
            rounded
            class="mx-1"
            :to="'to' in item ? item.to : ''"
            text
            color="white"
          >
            {{ item.title }}
          </v-btn>
        </template>
      </template>
    </v-app-bar>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data () {
    return {
      appBarColor: 'transparent',
      appBarElevation: 0,
      isMobile: false,
      drawer: false,
      links: [
        {
          icon: 'mdi-home',
          title: this.$i18n.t('router.index'),
          to: '/'
        },
        {
          icon: 'mdi-apps',
          title: this.$i18n.t('router.products'),
          scroll: 'Products'
        },
        {
          icon: 'mdi-tools',
          title: this.$i18n.t('router.resources'),
          to: '/Resources'
        },
        {
          icon: 'mdi-phone',
          title: this.$i18n.t('router.contact'),
          scroll: 'Contact'
        }
      ],
      title: 'Vuetify.js',
      showLogo: true
    }
  },

  watch: {
    isMobile () {
      this.showLogo = this.displayLogo()
    },

    $route () {
      this.handleScroll()
    }
  },

  beforeDestroy () {
    if (typeof window === 'undefined') return

    window.removeEventListener('resize', this.onResize, { passive: true })
    window.removeEventListener('scroll', this.handleDebouncedScroll)
  },

  mounted () {
    this.onResize()
    this.handleScroll()

    this.handleDebouncedScroll = debounce(this.handleScroll, 100)

    window.addEventListener('resize', this.onResize, { passive: true })
    window.addEventListener('scroll', this.handleDebouncedScroll)
  },

  methods: {
    displayLogo () {
      if (
        this.$route.name !== 'index' &&
        !this.$route.path.includes('japanese-clock')
      ) {
        return true
      } else if (!this.isMobile) {
        return true
      } else if (window.scrollY > 300) {
        return true
      } else {
        return false
      }
    },

    onResize () {
      this.isMobile = window.innerWidth < 600
    },

    handleScroll () {
      if (
        this.$route.name !== 'index' &&
        !this.$route.path.includes('japanese-clock')
      ) {
        this.appBarColor = 'secondary'
        this.appBarElevation = '4'
      } else if (window.scrollY > 300) {
        this.appBarColor = 'secondary'
        this.appBarElevation = '4'
      } else {
        this.appBarColor = 'transparent'
        this.appBarElevation = '0'
      }
      this.showLogo = this.displayLogo()
    },

    goToSection (id) {
      if (this.$route.name !== 'index') {
        this.$router.push('/')
      }
      setTimeout(() => {
        const elmnt = document.getElementById(id)
        elmnt.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }, 1)
    }
  }
}
</script>
