import Layout from '@/layouts/default.vue'
import Home from '@/pages/index.vue'

export default [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'index',
        component: Home
      }
    ]
  },
  {
    path: '/Resources',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'resources.index',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/resources/index.vue')
      },
      {
        path: '/Resources/open-source-libraries',
        name: 'resources.libraries',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/resources/open-source-libraries.vue')
      },
      {
        path: '/Resources/token-generator',
        name: 'resources.token-generator',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/resources/token-generator.vue')
      }
    ]
  }
]