import Vue from 'vue'
import App from './App.vue'
import { i18n, vuetify, assets, router } from '@/plugins'

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '@/assets/application.scss'

Vue.use(assets)

new Vue({
  i18n,
  vuetify,
  router,

  watch: {
    $route () {
      document.title = `${this.$i18n.t('app.title')}`
    }
  },

  mounted () {
    document.title = `${this.$i18n.t('app.title')}`
  },

  render: h => h(App)
}).$mount('#app')
