<template>
  <v-app dark>
    <gm-header style="z-index: 100" />

    <v-main class="ma-0 pa-0">
      <v-fade-transition>
        <router-view />
      </v-fade-transition>
    </v-main>

    <!-- <gm-footer></gm-footer> -->
  </v-app>
</template>

<script>
import Header from '~/components/Header.vue'
// import Footer from '~/components/Footer.vue'

export default {
  components: {
    'gm-header': Header
    // 'gm-footer': Footer,
  },

  data () {
    return {}
  }
}
</script>
