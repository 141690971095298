<template>
  <!-- eslint-disable vue/no-v-html -->
  <v-row align="center" justify="center" class="mx-0 webpage full-height">
    <v-col cols="12" md="6" lg="5">
      <v-row>
        <v-col
          cols="12"
          class="px-10"
          :class="$vuetify.breakpoint.width < 600 ? '' : 'py-12'"
        >
          <h2 class="gm-headline-2 grey--text text--darken-3">
            {{ $t('home.section5.question') }}
          </h2>
        </v-col>
        <v-col
          cols="12"
          class="px-10"
          :class="$vuetify.breakpoint.width < 600 ? '' : 'py-12'"
        >
          <span
            class="gm-headline-3 grey--text text--darken-3"
            v-html="$t('home.section5.subtitle')"
          />
        </v-col>
        <v-col
          cols="12"
          class="px-10"
          :class="$vuetify.breakpoint.width < 600 ? '' : 'py-12'"
        >
          <v-btn
            href="http://wa.me/50769793073"
            text
            rounded
            large
            color="grey darken-3"
          >
            {{ $t('actions.moreInformation') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="7"
      class="mx-auto pa-0 d-flex align-center justify-center"
    >
      <div class="gm-image__container">
        <div class="hover-control control--1" />
        <div class="hover-control control--2" />
        <div class="hover-control control--3" />
        <div class="hover-control control--4" />
        <div class="hover-control control--5" />
        <div class="hover-control control--6" />
        <div class="hover-control control--7" />
        <div class="hover-control control--8" />

        <v-img contain class="gm-image img--main" :src="$assets.five1" />
        <v-img contain class="gm-image img--1" :src="$assets.five2" />
        <v-img contain class="gm-image img--2" :src="$assets.five3" />
        <v-img contain class="gm-image img--3" :src="$assets.five4" />
        <v-img contain class="gm-image img--4" :src="$assets.five5" />
        <v-img contain class="gm-image img--5" :src="$assets.five6" />
        <v-img contain class="gm-image img--6" :src="$assets.five7" />
        <v-img contain class="gm-image img--7" :src="$assets.five8" />
        <v-img contain class="gm-image img--8" :src="$assets.five9" />

        <span
          class="gm-title title--1 text-center black--text d-block"
          v-html="$t('home.section5.products.solutions')"
        />
        <span
          class="gm-title title--2 text-center black--text d-block"
          v-html="$t('home.section5.products.automation')"
        />
        <span
          class="gm-title title--3 text-center black--text d-block"
          v-html="$t('home.section5.products.events')"
        />
        <span
          class="gm-title title--4 text-center black--text d-block"
          v-html="$t('home.section5.products.intelligence')"
        />
        <span
          class="gm-title title--5 text-center black--text d-block"
          v-html="$t('home.section5.products.integration')"
        />
        <span
          class="gm-title title--6 text-center black--text d-block"
          v-html="$t('home.section5.products.fusion')"
        />
        <span
          class="gm-title title--7 text-center black--text d-block"
          v-html="$t('home.section5.products.business')"
        />
        <span
          class="gm-title title--8 text-center black--text d-block"
          v-html="$t('home.section5.products.artificialIntelligence')"
        />
      </div>
    </v-col>
  </v-row>
  <!-- eslint-enable vue/no-v-html -->
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
span {
  font-size: 1.15rem;
}

.gm-image {
  transform: translatey(0px);

  &.img {
    &--main {
      transition: all 0.25s ease !important;
      width: 75%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
    &--1 {
      transition: all 0.25s ease !important;
      width: 24%;
      top: 16%;
      left: 4%;
      z-index: 2;
    }
    &--2 {
      transition: all 0.25s ease !important;
      width: 24%;
      top: 7%;
      left: 25%;
      z-index: 3;
    }
    &--3 {
      transition: all 0.25s ease !important;
      width: 24%;
      top: 9%;
      left: 52%;
      z-index: 4;
    }
    &--4 {
      transition: all 0.25s ease !important;
      width: 24%;
      top: 19%;
      left: 77%;
      z-index: 5;
    }
    &--5 {
      transition: all 0.25s ease !important;
      width: 26%;
      top: 51%;
      left: 76%;
      z-index: 6;
    }
    &--6 {
      transition: all 0.25s ease !important;
      width: 26%;
      top: 57%;
      left: 52%;
      z-index: 7;
    }
    &--7 {
      transition: all 0.25s ease !important;
      width: 26%;
      top: 65%;
      left: 22%;
      z-index: 8;
    }
    &--8 {
      transition: all 0.25s ease !important;
      width: 26%;
      top: 49%;
      left: -5%;
      z-index: 9;
    }
  }
}

.gm-title {
  transition: all 0.25s ease !important;
  position: absolute;
  opacity: 0;
  // background: rgba(0, 0, 0, 0.5);

  &.title {
    &--1 {
      width: 30%;
      z-index: 10;
      top: 26%;
      left: -22%;
    }
    &--2 {
      width: 35%;
      z-index: 10;
      top: -2%;
      left: 19%;
    }
    &--3 {
      width: 35%;
      z-index: 10;
      top: 0%;
      left: 47%;
    }
    &--4 {
      width: 27%;
      z-index: 10;
      top: 2%;
      left: 75%;
    }
    &--5 {
      width: 27%;
      z-index: 10;
      top: 73%;
      left: 76%;
    }
    &--6 {
      width: 35%;
      z-index: 10;
      top: 82%;
      left: 47%;
    }
    &--7 {
      width: 35%;
      z-index: 10;
      top: 86%;
      left: 17%;
    }
    &--8 {
      width: 35%;
      z-index: 10;
      top: 73%;
      left: -9%;
    }
  }
}

.hover-control {
  position: absolute;
  // background: rgba(0, 0, 0, 0.5);
  width: 21%;
  height: 21%;

  &.control {
    &--1 {
      top: 24%;
      left: 5%;
      z-index: 11;

      &:hover {
        & ~ .img--1 {
          transform: translatey(-5px);
        }

        & ~ .title--1 {
          opacity: 1;
        }
      }
    }
    &--2 {
      top: 10%;
      left: 26%;
      z-index: 11;

      &:hover {
        & ~ .img--2 {
          transform: translatey(-5px);
        }

        & ~ .title--2 {
          opacity: 1;
        }
      }
    }
    &--3 {
      top: 10%;
      left: 54%;
      z-index: 11;

      &:hover {
        & ~ .img--3 {
          transform: translatey(-5px);
        }

        & ~ .title--3 {
          opacity: 1;
        }
      }
    }
    &--4 {
      top: 24%;
      left: 78%;
      z-index: 11;

      &:hover {
        & ~ .img--4 {
          transform: translatey(-5px);
        }

        & ~ .title--4 {
          opacity: 1;
        }
      }
    }
    &--5 {
      top: 52%;
      left: 79%;
      z-index: 11;

      &:hover {
        & ~ .img--5 {
          transform: translatey(-5px);
        }

        & ~ .title--5 {
          opacity: 1;
        }
      }
    }
    &--6 {
      top: 61%;
      left: 54%;
      z-index: 11;

      &:hover {
        & ~ .img--6 {
          transform: translatey(-5px);
        }

        & ~ .title--6 {
          opacity: 1;
        }
      }
    }
    &--7 {
      top: 65%;
      left: 24%;
      z-index: 11;

      &:hover {
        & ~ .img--7 {
          transform: translatey(-5px);
        }

        & ~ .title--7 {
          opacity: 1;
        }
      }
    }
    &--8 {
      top: 52%;
      left: -2%;
      z-index: 11;

      &:hover {
        & ~ .img--8 {
          transform: translatey(-5px);
        }

        & ~ .title--8 {
          opacity: 1;
        }
      }
    }
  }
}
</style>
