<template>
  <v-row align="center" justify="center" class="mx-0 webpage full-height">
    <v-col cols="12" md="6" lg="5">
      <v-row>
        <v-col
          cols="12"
          class="d-flex flex-column justify-center"
          :class="$vuetify.breakpoint.width < 600 ? '' : 'py-12'"
        >
          <v-row align="center" justify="center">
            <v-col class="pa-0">
              <div class="carousel">
                <div class="carousel__item">
                  <span class="gm-headline-3">
                    {{ $t('home.section3.carousel.evolves') }}
                  </span>
                </div>
                <div class="carousel__item">
                  <span class="gm-headline-3">
                    {{ $t('home.section3.carousel.controls') }}
                  </span>
                </div>
                <div class="carousel__item">
                  <span class="gm-headline-3">
                    {{ $t('home.section3.carousel.automate') }}
                  </span>
                </div>
                <div class="carousel__item">
                  <span class="gm-headline-3">
                    {{ $t('home.section3.carousel.expand') }}
                  </span>
                </div>
                <div class="carousel__item">
                  <span class="gm-headline-3">
                    {{ $t('home.section3.carousel.makesProfitable') }}
                  </span>
                </div>
              </div>
            </v-col>

            <v-col
              :class="
                $vuetify.breakpoint.width < 600 ? 'pl-1 mr-5' : 'pl-2 mr-7'
              "
            >
              <span class="gm-headline-3">
                {{ $t('home.section3.slogan')[0] }}
              </span>
            </v-col>
          </v-row>
          <h2 class="text-center">
            <span class="gm-headline-2">
              {{ $t('home.section3.slogan')[1] }}
            </span>
          </h2>
        </v-col>

        <v-col
          cols="12"
          class="px-10 d-flex justify-center"
          :class="$vuetify.breakpoint.width < 600 ? '' : 'py-12'"
        >
          <v-btn
            href="http://wa.me/50769793073"
            text
            rounded
            large
            color="white"
          >
            {{ $t('actions.moreInformation') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="7"
      class="mx-auto pa-0 d-flex align-center justify-center"
    >
      <v-img max-width="40vw" class="gm-floating-img" :src="$assets.three1" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
$animation-timing: 20s;
$carousel-items: 5;
$animation-delay-fraction: $animation-timing / $carousel-items;
$animation-steps-fraction: 100 / $carousel-items;
$slide-change-timing: 3; // percentage of total animation cycle

.carousel {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.carousel__item {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  opacity: 0;
  // filter: drop-shadow(0 2px 2px #555);
  will-change: transform, opacity;
  animation: carousel-animate-vertical $animation-timing linear infinite;

  span {
    margin-left: auto;
  }
}

@for $i from 1 through ($carousel-items - 1) {
  .carousel__item:nth-child(#{$i}) {
    animation-delay: calc(#{$animation-delay-fraction} * #{$i - 2});
  }
}

.carousel__item:last-child {
  animation-delay: calc(-#{$animation-delay-fraction} * 2);
}

// example without negative delays
// @for $i from 1 through ($carousel-items) {
//   .carousel__item:nth-child(#{$i}) {
//     animation-delay: calc(#{$animation-delay-fraction} * #{$i});
//   }
// }

@keyframes carousel-animate-vertical {
  0% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
  #{$slide-change-timing}%,
  #{$animation-steps-fraction}% {
    transform: translateY(-100%) scale(0.7);
    opacity: 0.4;
    visibility: visible;
  }
  #{$animation-steps-fraction + $slide-change-timing}%,
  #{$animation-steps-fraction * 2}% {
    transform: translateY(0) scale(1);
    opacity: 1;
    visibility: visible;
  }
  #{($animation-steps-fraction * 2) + $slide-change-timing}%,
  #{$animation-steps-fraction * 3}% {
    transform: translateY(100%) scale(0.7);
    opacity: 0;
    visibility: visible;
  }
  #{($animation-steps-fraction * 3) + $slide-change-timing}% {
    transform: translateY(100%) scale(0.5);
    opacity: 0;
    visibility: visible;
  }
  100% {
    transform: translateY(100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
}
</style>
