<template>
  <v-row align="start" justify="center" class="mx-0 pt-12 webpage full-height">
    <div
      v-if="$vuetify.breakpoint.width >= 600"
      class="gm-logo--mask primary"
    />
    <img
      v-if="$vuetify.breakpoint.width >= 600"
      class="gm-logo--full-height"
      :src="$assets.logoWhiteCut"
    >
    <img
      v-if="$vuetify.breakpoint.width >= 600"
      class="gm-background--full-height"
      :src="$assets.seven1"
    >
    <v-col
      cols="12"
      class="d-flex align-end justify-center"
      :class="$vuetify.breakpoint.width < 600 ? '' : 'py-12 gm-information'"
    >
      <v-img max-width="450px" :src="$assets.logoWhite" />
      <h2 class="ml-12 gm-headline-1">{{ $t('home.section7.title') }}</h2>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.webpage {
  position: relative;
  overflow-y: hidden;
}

.gm-logo {
  &--full-height {
    position: absolute;
    height: 80vh;
    bottom: -2%;
    left: -2%;
    z-index: 2;
  }

  &--mask {
    position: absolute;
    width: 20vw;
    height: 100vh;
    left: 0;
    z-index: 1;
    bottom: 0;
  }
}

.gm-background {
  &--full-height {
    position: absolute;
    height: 75vh;
    bottom: -2%;
    right: 0;
  }
}

.gm-information {
  z-index: 2;
  padding-left: 40vh;
}
</style>
