<template>
  <!-- eslint-disable vue/no-v-html -->
  <v-row align="center" justify="center" class="mx-0 webpage full-height">
    <v-col cols="12" md="5" lg="4">
      <v-row>
        <v-col
          cols="12"
          class="px-10"
          :class="$vuetify.breakpoint.width < 600 ? '' : 'py-12'"
        >
          <span class="gm-headline-3" v-html="$t('home.section2.title')[0]" />
          <br>
          <h2>
            <span class="gm-headline-2">{{
              $t('home.section2.title')[1]
            }}</span>
          </h2>
        </v-col>

        <v-col
          cols="12"
          class="px-10"
          :class="$vuetify.breakpoint.width < 600 ? '' : 'py-12'"
        >
          <v-btn
            href="http://wa.me/50769793073"
            text
            rounded
            large
            color="white"
          >
            {{ $t('actions.moreInformation') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="7"
      lg="8"
      class="mx-auto pa-0 d-flex align-center justify-center"
    >
      <div class="gm-image__container">
        <div class="hover-control control--1" />
        <div class="hover-control control--2" />
        <div class="hover-control control--3" />
        <div class="hover-control control--4" />

        <v-img contain class="gm-image img--1" :src="$assets.two1" />
        <v-img contain class="gm-image img--2" :src="$assets.two2" />
        <v-img contain class="gm-image img--3" :src="$assets.two3" />
        <v-img contain class="gm-image img--4" :src="$assets.two4" />
        <v-img contain class="gm-image img--5" :src="$assets.two5" />

        <img-btn
          absolute
          class="btn-line--1"
          width="30%"
          :title="$t('home.section2.tooltips.integration')"
          :src="$assets.integration"
          color="white"
        />
        <img-btn
          absolute
          class="btn-line--2"
          :title="$t('home.section2.tooltips.flexibility')"
          :src="$assets.flexibility"
          color="white"
        />
        <img-btn
          absolute
          class="btn-line--3"
          :title="$t('home.section2.tooltips.modular')"
          :src="$assets.modular"
          color="white"
        />
        <img-btn
          absolute
          width="30%"
          class="btn-line--4"
          :src="$assets.connectivity"
          color="white"
        />
      </div>
    </v-col>
  </v-row>
  <!-- eslint-enable vue/no-v-html -->
</template>

<script>
import ImgBtn from '~/components/utils/ImgBtn.vue'

export default {
  components: {
    'img-btn': ImgBtn
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.gm-image {
  transform: translatey(0px);

  &.img {
    &--1 {
      transition: all 0.25s ease !important;
      width: 23%;
      top: 61%;
      left: 2%;
      z-index: 2;
    }
    &--2 {
      transition: all 0.25s ease !important;
      width: 26%;
      top: 38%;
      left: 39%;
      z-index: 3;
    }
    &--3 {
      transition: all 0.25s ease !important;
      width: 9%;
      top: 44%;
      left: 50%;
      z-index: 5;
    }
    &--4 {
      transition: all 0.25s ease !important;
      width: 15%;
      top: 39%;
      left: 55%;
      z-index: 4;
    }
    &--5 {
      transition: all 0.25s ease !important;
      width: 100%;
      top: 12%;
      left: 0%;
      z-index: 1;
    }
  }
}

.btn-line {
  &--1 {
    opacity: 0;
    z-index: 6;
    bottom: 31%;
    left: -1%;
  }

  &--2 {
    opacity: 0;
    bottom: 25%;
    left: 13%;
  }

  &--3 {
    opacity: 0;
    bottom: 17%;
    left: 29%;
  }

  &--4 {
    opacity: 0;
    bottom: 45%;
    left: 49%;
  }
}

.hover-control {
  position: absolute;
  // background: rgba(0, 0, 0, 0.5);
  width: 20%;
  height: 20%;

  &.control {
    &--1 {
      top: 62%;
      left: 4%;
      z-index: 7;

      &:hover {
        & ~ .img--1 {
          transform: translatey(-5px);
        }

        & ~ .btn-line--1 {
          opacity: 1;
        }
      }
    }
    &--2 {
      height: 22%;
      top: 40%;
      left: 30%;
      z-index: 7;

      &:hover {
        & ~ .img--2 {
          transform: translatey(-5px);
        }

        & ~ .btn-line--2 {
          opacity: 1;
        }
      }
    }
    &--3 {
      width: 8%;
      height: 33%;
      top: 40%;
      left: 50%;
      z-index: 7;

      &:hover {
        & ~ .img--3 {
          transform: translatey(-5px);
        }

        & ~ .btn-line--3 {
          opacity: 1;
        }
      }
    }
    &--4 {
      top: 40%;
      left: 58%;
      z-index: 7;

      &:hover {
        & ~ .img--4 {
          transform: translatey(-5px);
        }

        & ~ .btn-line--4 {
          opacity: 1;
        }
      }
    }
  }
}
</style>
