<template>
  <div class="gm-image__container rectangle">
    <v-img class="img-slider--bg" :src="$assets.background1" />
  </div>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.webpage {
  padding-right: 0px !important;
}

.img-slider {
  &--bg {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
