<template>
  <v-row align="center" justify="center" class="mx-0 webpage full-height">
    <v-col cols="12" md="4" lg="3">
      <v-row>
        <v-col
          cols="12"
          class="d-flex align-center justify-star"
          :class="$vuetify.breakpoint.width < 600 ? '' : 'py-12'"
          style="z-index: 10"
        >
          <v-img
            width="170px"
            max-width="170px"
            :src="$assets.circleSlider"
          />
          <div class="d-flex flex-column">
            <template v-for="(item, i) in slideItems">
              <v-btn
                :key="i"
                width="180px"
                class="my-2"
                rounded
                :outlined="active !== i && active !== null"
                :color="item.color"
                :style="{
                  transform: `translatex(${item.translate}px)`,
                }"
                @click="active = i"
              >
                {{ item.text }}
              </v-btn>
            </template>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="8"
      lg="9"
      class="mx-auto pa-0 d-flex align-center justify-end"
    >
      <v-slide-x-reverse-transition hide-on-leave>
        <gm-slider-default v-if="active === null" />

        <gm-slider-logistics v-if="active == 0" />

        <gm-slider-health v-if="active == 1" />

        <gm-slider-energy v-if="active == 2" />
        <!-- <template v-for="n in 6">
          <v-card v-if="active === n - 1" :key="n" light>
            <v-card-text>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto
              alias earum exercitationem, non dignissimos reprehenderit
              reiciendis magni sequi quaerat illo repudiandae, repellendus totam
              aperiam nesciunt aspernatur quia explicabo neque atque?
            </v-card-text>
          </v-card>
        </template> -->
      </v-slide-x-reverse-transition>
    </v-col>
  </v-row>
</template>

<script>
import Default from './slider/Default.vue'
import Logistics from './slider/Logistics.vue'
import Health from './slider/Health.vue'
import Energy from './slider/Energy.vue'

export default {
  components: {
    'gm-slider-default': Default,
    'gm-slider-logistics': Logistics,
    'gm-slider-health': Health,
    'gm-slider-energy': Energy
  },

  data () {
    return {
      active: null
    }
  },

  computed: {
    slideItems () {
      return [
        {
          text: this.$i18n.t('home.section6.slider.logistics'),
          color: 'primary',
          translate: -12
        },
        {
          text: this.$i18n.t('home.section6.slider.health'),
          color: 'primary lighten-1',
          translate: 0
        },
        {
          text: this.$i18n.t('home.section6.slider.energy'),
          color: 'primary',
          translate: -12
        }
      ]
      /* return [
        { text: 'Logística', translate: -55 },
        { text: 'Salud', translate: -12 },
        { text: 'Energía', translate: 0 },
        { text: 'Sensores', translate: 0 },
        { text: 'Rastro', translate: -12 },
        { text: 'Gestión de activos', translate: -55 },
      ] */
    }
  }
}
</script>

<style lang="scss" scoped>
.webpage {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>
