<template>
  <v-row
    align="center"
    justify="center"
    class="mx-0 webpage full-height section-1"
  >
    <v-col cols="12" md="6" lg="5">
      <v-row>
        <v-col
          cols="12"
          :class="$vuetify.breakpoint.width < 600 ? '' : 'py-12'"
        >
          <v-img max-width="450px" :src="$assets.logoWhite" />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="px-10"
          :class="$vuetify.breakpoint.width < 600 ? '' : 'py-12'"
        >
          <span class="gm-headline-2">{{ $t('home.section1.slogan')[0] }}</span><br>
          <h1>
            <span class="gm-headline-1">
              {{ $t('home.section1.slogan')[1] }}
            </span>
          </h1>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="7"
      class="mx-auto pa-0 d-flex align-center justify-center"
    >
      <div class="gm-image__container">
        <div class="hover-control control--1" />
        <div class="hover-control control--2" />
        <div class="hover-control control--3" />
        <div class="hover-control control--4" />

        <v-img contain class="gm-image img--1" :src="$assets.one1" />
        <v-img contain class="gm-image img--2" :src="$assets.one2" />
        <v-img contain class="gm-image img--3" :src="$assets.one3" />
        <v-img contain class="gm-image img--4" :src="$assets.one4" />

        <img-btn
          absolute
          class="btn-line--1"
          width="40%"
          :title="$t('home.section1.tooltips.connectivity')"
          :src="$assets.connectivity"
          color="white"
        />
        <img-btn
          absolute
          class="btn-line--2"
          width="30%"
          :title="$t('home.section1.tooltips.flexibility')"
          :src="$assets.flexibility"
          color="white"
        />
        <img-btn
          absolute
          class="btn-line--3"
          width="40%"
          :title="$t('home.section1.tooltips.integration')"
          :src="$assets.integration"
          color="white"
        />
        <img-btn
          absolute
          class="btn-line--4"
          width="36%"
          :title="$t('home.section1.tooltips.modular')"
          :src="$assets.modular"
          color="white"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ImgBtn from '~/components/utils/ImgBtn.vue'

export default {
  components: {
    'img-btn': ImgBtn
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.gm-image {
  transform: translatey(0px);

  &.img {
    &--1 {
      transition: all 0.25s ease !important;
      width: 25%;
      top: 50%;
      left: -12%;
      z-index: 4;
    }
    &--2 {
      transition: all 0.25s ease !important;
      width: 48%;
      top: 22%;
      left: 8%;
      z-index: 2;
    }
    &--3 {
      transition: all 0.25s ease !important;
      width: 100%;
      top: 20%;
      z-index: 3;
    }
    &--4 {
      transition: all 0.25s ease !important;
      width: 62%;
      top: 18%;
      left: 48%;
      z-index: 1;
    }
  }
}

.btn-line {
  &--1 {
    opacity: 0;
    z-index: 5;
    bottom: 30%;
    left: -12%;
  }

  &--2 {
    opacity: 0;
    bottom: 48%;
    left: 18%;
  }

  &--3 {
    opacity: 0;
    z-index: 5;
    bottom: 38%;
    left: 41%;
  }

  &--4 {
    opacity: 0;
    bottom: 43%;
    left: 66%;
  }
}

.hover-control {
  position: absolute;
  // background: rgba(0, 0, 0, 0.5);
  width: 30%;
  height: 34%;

  &.control {
    &--1 {
      top: 55%;
      left: -12%;
      z-index: 6;

      &:hover {
        & ~ .img--1 {
          transform: translatey(-5px);
        }

        & ~ .btn-line--1 {
          opacity: 1;
        }
      }
    }
    &--2 {
      top: 30%;
      left: 18%;
      z-index: 6;

      &:hover {
        & ~ .img--2 {
          transform: translatey(-5px);
        }

        & ~ .btn-line--2 {
          opacity: 1;
        }
      }
    }
    &--3 {
      width: 66%;
      top: 64%;
      left: 18%;
      z-index: 6;

      &:hover {
        & ~ .img--3 {
          transform: translatey(-5px);
        }

        & ~ .btn-line--3 {
          opacity: 1;
        }
      }
    }
    &--4 {
      top: 30%;
      left: 70%;
      z-index: 6;

      &:hover {
        & ~ .img--4 {
          transform: translatey(-5px);
        }

        & ~ .btn-line--4 {
          opacity: 1;
        }
      }
    }
  }
}
</style>
