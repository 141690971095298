<template>
  <!-- eslint-disable vue/no-v-html -->
  <v-row align="center" justify="center" class="mx-0 webpage full-height">
    <v-col cols="12" md="6" lg="5">
      <v-row>
        <v-col
          cols="12"
          class="px-10"
          :class="$vuetify.breakpoint.width < 600 ? '' : 'py-12'"
        >
          <span
            class="gm-headline-2 grey--text text--darken-3"
            v-html="$t('home.section4.title')"
          />
          <br>
          <span
            class="gm-headline-3 grey--text text--darken-3"
            v-html="$t('home.section4.subtitle')"
          />
        </v-col>

        <v-col
          cols="12"
          class="px-10"
          :class="$vuetify.breakpoint.width < 600 ? '' : 'py-12'"
        >
          <v-btn
            href="http://wa.me/50769793073"
            text
            rounded
            large
            color="grey darken-3"
          >
            {{ $t('actions.moreInformation') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="7"
      class="mx-auto pa-0 d-flex align-center justify-center"
    >
      <v-img max-width="40vw" class="gm-floating-img" :src="$assets.four1" />
    </v-col>
    <!-- <v-col
      cols="12"
      md="6"
      lg="7"
      class="mx-auto pa-0 d-flex align-center justify-center"
    >
      <div class="gm-image__container">
        <div class="hover-control control--1"></div>
        <div class="hover-control control--2"></div>
        <div class="hover-control control--3"></div>
        <div class="hover-control control--4"></div>

        <v-img contain class="gm-image img--1" :src="$assets.four1" />
        <v-img contain class="gm-image img--2" :src="$assets.four2" />
        <v-img contain class="gm-image img--3" :src="$assets.four3" />
        <v-img contain class="gm-image img--4" :src="$assets.four4" />
      </div>
    </v-col> -->
  </v-row>
  <!-- eslint-enable vue/no-v-html -->
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.gm-image {
  transform: translatey(0px);

  &.img {
    &--1 {
      transition: all 0.25s ease !important;
      width: 100%;
      top: 45%;
      left: -40%;
      z-index: 4;
    }
    &--2 {
      transition: all 0.25s ease !important;
      width: 100%;
      top: 17%;
      left: -22%;
      z-index: 1;
    }
    &--3 {
      transition: all 0.25s ease !important;
      width: 100%;
      top: 46%;
      left: 14%;
      z-index: 3;
    }
    &--4 {
      transition: all 0.25s ease !important;
      width: 100%;
      top: 34%;
      left: -2%;
      z-index: 2;
    }
  }
}

.hover-control {
  position: absolute;
  // background: rgba(0, 0, 0, 0.5);
  width: 24%;
  height: 24%;

  &.control {
    &--1 {
      top: 66%;
      left: 2%;
      z-index: 7;

      &:hover {
        & ~ .img--1 {
          transform: translatey(-5px);
        }

        & ~ .btn-line--1 {
          opacity: 1;
        }
      }
    }
    &--2 {
      top: 42%;
      left: 20%;
      z-index: 7;

      &:hover {
        & ~ .img--2 {
          transform: translatey(-5px);
        }

        & ~ .btn-line--2 {
          opacity: 1;
        }
      }
    }
    &--3 {
      width: 11%;
      height: 34%;
      top: 45%;
      left: 44%;
      z-index: 7;

      &:hover {
        & ~ .img--3 {
          transform: translatey(-5px);
        }

        & ~ .btn-line--3 {
          opacity: 1;
        }
      }
    }
    &--4 {
      top: 45%;
      left: 55%;
      z-index: 7;

      &:hover {
        & ~ .img--4 {
          transform: translatey(-5px);
        }

        & ~ .btn-line--4 {
          opacity: 1;
        }
      }
    }
  }
}
</style>
