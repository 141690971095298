<template>
  <v-app>
    <transition name="component-fade" mode="out-in">
      <router-view />
    </transition>
  </v-app>
</template>

<script>
export default {}
</script>

<style>
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
