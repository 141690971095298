<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="d-flex flex-column align-center" :style="btnStyle">
    <span
      v-if="title !== ''"
      class="text-center white--text"
      style="font-size: 1.5rem"
      v-html="title"
    />
    <v-btn x-large icon outlined :color="color" class="pa-2 btn--brightness">
      <v-img v-if="src !== ''" width="24px" contain :src="src" />
      <v-icon v-else>{{ icon }}</v-icon>
    </v-btn>
    <div id="btnLine" :style="btnLine">
      <v-progress-linear
        :color="color"
        buffer-value="0"
        stream
      />
    </div>
  </div>
  <!-- eslint-enable vue/no-v-html -->
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => ''
    },

    width: {
      type: String,
      default: () => '50%'
    },

    color: {
      type: String,
      default: () => 'white'
    },

    icon: {
      type: String,
      default: () => ''
    },

    src: {
      type: String,
      default: () => ''
    },

    absolute: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {}
  },

  computed: {
    btnStyle () {
      return {
        position: this.absolute ? 'absolute' : '',
        width: `${this.width}`,
        transition: 'all 0.25s ease'
      }
    },

    btnLine () {
      return {
        position: 'relative',
        width: '100%'
        // background: 'rgba(0,0,0,0.2)',
      }
    }
  },

  mounted () {
    this.applyCSS()
  },

  beforeUpdate () {
    this.applyCSS()
  },

  methods: {
    applyCSS () {
      const cssRule = `#btnLine:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }`
      const style = document.createElement('style')
      style.type = 'text/css'
      // append the style node as child of current component
      this.$el.appendChild(style)
      style.innerHTML = cssRule
    }
  }
}
</script>

<style lang="scss" scoped>
.v-progress-linear {
  position: absolute;
  bottom: 50%;
  transform: rotate(90deg);
}

.btn--brightness {
  -webkit-box-shadow: 0px 0px 35px 0px rgba(255, 255, 255, 0.3);
  box-shadow: 0px 0px 35px 0px rgba(255, 255, 255, 0.3);
}
</style>
