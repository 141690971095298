const assets = {}

assets.install = (Vue) => {
  Vue.prototype.$assets = {
    logoWhite: require('~/assets/logo_white.svg'),
    logoNormalCut: require('~/assets/img/favicon_blue_cut.svg'),
    logoWhiteCut: require('~/assets/img/favicon_white_cut.svg'),

    connectivity: require('~/assets/img/icons/connectivity.png'),
    flexibility: require('~/assets/img/icons/flexibility.png'),
    integration: require('~/assets/img/icons/integration.png'),
    modular: require('~/assets/img/icons/modular.png'),

    // Section One
    one1: require('~/assets/img/section_one/element1.svg'),
    one2: require('~/assets/img/section_one/element2.svg'),
    one3: require('~/assets/img/section_one/element3.svg'),
    one4: require('~/assets/img/section_one/element4.svg'),

    // Section Two
    two1: require('~/assets/img/section_two/element1.svg'),
    two2: require('~/assets/img/section_two/element2.svg'),
    two3: require('~/assets/img/section_two/element3.svg'),
    two4: require('~/assets/img/section_two/element4.svg'),
    two5: require('~/assets/img/section_two/element5.svg'),

    // Section Three
    three1: require('~/assets/img/section_three/element1.svg'),

    // Section Four
    four1: require('~/assets/img/section_four/element1.svg'),
    // four2: require('~/assets/img/section_four/el12.png'),
    // four3: require('~/assets/img/section_four/el13.png'),
    // four4: require('~/assets/img/section_four/el14.png'),

    // Section Five
    five1: require('~/assets/img/section_five/element1.svg'),
    five2: require('~/assets/img/section_five/element2.svg'),
    five3: require('~/assets/img/section_five/element3.svg'),
    five4: require('~/assets/img/section_five/element4.svg'),
    five5: require('~/assets/img/section_five/element5.svg'),
    five6: require('~/assets/img/section_five/element6.svg'),
    five7: require('~/assets/img/section_five/element7.svg'),
    five8: require('~/assets/img/section_five/element8.svg'),
    five9: require('~/assets/img/section_five/element9.svg'),

    // Section Six
    circleSlider: require('~/assets/img/section_six/circleSlider.svg'),
    background1: require('~/assets/img/section_six/background1.svg'),
    bgLogistics: require('~/assets/img/section_six/bg_logistics.svg'),
    bgEnergy: require('~/assets/img/section_six/bg_energy.svg'),
    bgHealth: require('~/assets/img/section_six/bg_health.svg'),

    // Logos
    tenvio: require('~/assets/img//logos_layrz/tenvio_app.svg'),
    launchpad: require('~/assets/img/logos_layrz/launchpad_app.svg'),
    concierge: require('~/assets/img/logos_layrz/concierge.svg'),
    fusion: require('~/assets/img/logos_layrz/fusion_app.svg'),
    repcom: require('~/assets/img/logos_layrz/repcom_app.svg'),
    layrz: require('~/assets/img/logos_layrz/layrz_app.svg'),
    goldenmAirService: require('~/assets/img/logos_layrz/goldenM_IA_Service.svg'),
    mfence: require('~/assets/img/logos_layrz/mfence_app.svg'),
    gaslp: require('~/assets/img/logos_layrz/gaslp_app.svg'),

    // Section Seven
    seven1: require('~/assets/img/section_seven/element1.svg'),

    // Section Eight
    eight1: require('~/assets/img/section_eight/element1.svg'),
    eight2: require('~/assets/img/section_eight/element2.svg')
  }
}

export default assets