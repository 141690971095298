<template>
  <div class="webpage main">
    <!-- Section One -->
    <gm-section-one class="pt-3 px-5 primary" />
    <!-- /Section One -->

    <!-- Section Two -->
    <gm-section-two class="px-5 primary" />
    <!-- /Section Two -->

    <!-- Section Three -->
    <gm-section-three class="px-5 primary" />
    <!-- /Section Three -->

    <!-- Section Four -->
    <gm-section-four class="px-5 white" />
    <!-- /Section Four -->

    <!-- Section Five -->
    <gm-section-five id="Products" class="px-5 white" />
    <!-- /Section Five -->

    <!-- Section Six -->
    <gm-section-six class="px-5 white" />
    <!-- /Section Six -->

    <!-- Section Seven -->
    <gm-section-seven class="px-5 primary" />
    <!-- /Section Seven -->

    <!-- Section Eight -->
    <gm-section-eight id="Contact" class="px-5 white" />
    <!-- /Section Eight -->
  </div>
</template>

<script>
import {
  SectionOne,
  SectionTwo,
  SectionThree,
  SectionFour,
  SectionFive,
  SectionSix,
  SectionSeven,
  SectionEight
} from '~/components/home'

export default {
  components: {
    'gm-section-one': SectionOne,
    'gm-section-two': SectionTwo,
    'gm-section-three': SectionThree,
    'gm-section-four': SectionFour,
    'gm-section-five': SectionFive,
    'gm-section-six': SectionSix,
    'gm-section-seven': SectionSeven,
    'gm-section-eight': SectionEight
  },

  data () {
    return {}
  },

  head () {
    return {
      title: this.$i18n.t('app.index'),

      meta: [
        {
          name: 'description',
          content: this.$i18n.t('seo.default.description')
        },
        {
          name: 'robots',
          content: 'index, follow'
        },
        {
          name: 'keywords',
          content: this.$i18n.t('seo.default.keywords')
        },
        {
          property: 'og:title',
          content: this.$i18n.t('app.index')
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:locale',
          content: this.$i18n.locale
        },
        {
          property: 'og:url',
          content: 'https://goldenmcorp.com/resources'
        },
        {
          property: 'og:image',
          content: 'https://cdn.goldenm.space/website/seo/landing.jpg'
        },
        {
          property: 'og:description',
          content: this.$i18n.t('seo.default.description')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped></style>
